@import "./fonts";

body {
  background: $bright-white;
  font-family: 'Comfortaa', Helvetica, Arial, sans-serif;
  font-weight: 400;
}

div {
  border-radius: 5px;
  padding: 5px;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

.tooltip .tooltip-inner, .tooltip .tooltip-arrow {
  font-size: 12px; /* Font size */
  max-width: 1000px; /* Maximum width */
  white-space: nowrap;
}

.brand-text {
  color: $greenbriar;
  font-family: 'Comfortaa';
  font-weight: 700;
}