// Colors
$bright-white: #FFFFFF;

// Under The Sea Pantone 2019 color pallette
$living-coral: #FA7268;
$blue-depths: #263056;
$turkish-sea: #195190;
$greenbriar: #4B9B69;
$viridian-green: #009499;
$turtle-green: #81894E;
$sea-pink: #DE98AB;
$vibrant-yellow: #FFDA29;
$limpet-shell: #98DDDE;

// Spacing
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;
$desktop-breakpoint: 45rem;
