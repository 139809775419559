[data-amplify-authenticator] {
  --amplify-components-button-primary-background-color: var(--amplify-colors-green-60);
  --amplify-components-button-primary-active-background-color: var(--amplify-colors-green-60);
  --amplify-components-button-primary-visited-background-color: var(--amplify-colors-green-60);
  --amplify-components-button-primary-focus-background-color: var(--amplify-colors-green-60);
  --amplify-components-button-primary-hover-background-color: var(--amplify-colors-green-40);
  --amplify-components-button-link-color: var(--amplify-colors-green-60);
  --amplify-components-button-link-hover-color: var(--amplify-colors-green-60);
  --amplify-components-button-link-focus-color: var(--amplify-colors-green-60);
  --amplify-components-button-link-active-color: var(--amplify-colors-green-20);
  --amplify-components-button-link-hover-background-color: var(--amplify-colors-green-20);
  --amplify-components-button-link-active-background-color: var(--amplify-colors-green-40);
  --amplify-components-tabs-item-active-color: var(--amplify-colors-green-60);
  --amplify-components-tabs-item-focus-color: var(--amplify-colors-green-60);
  --amplify-components-tabs-item-hover-color: var(--amplify-colors-green-80);
  --amplify-components-tabs-item-active-border-color: var(--amplify-colors-green-80);
}

// we want the amplify auth container to fit in the parent modal
div [data-amplify-authenticator] [data-amplify-container] {
  width: auto;
}

// we want the amplify auth router div to just lay flat on
// the parent modal without border or shadow
div [data-amplify-authenticator] [data-amplify-router] {
  border: none;
  box-shadow: none;
}